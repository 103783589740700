<template>	  
	  <div class="contact" id="contact">
	  	<Masthead :height="300" :noLetters="true" />
	  	<div class="container" style="padding-top:45px;">
		      <div class="row">
		        <div class="col-5 pl-3 text-left">
		          
		        </div>
		      </div>
		      <div class="row">
		        <div class="col-12 col-md-5 pt-4 pb-4 text-left" data-aos="fade-down">
		          <h2 class="text-left text-white" style="border-bottom: 1px solid black;">{{ $t('Contact') }}</h2>
		          <!--  <p class="text-white">{{ $t('company') }}</p> -->
		          <p class="text-white">STALMAT Daria Walczak</p>
		          <p class="text-white">
          		  	ul. Zbójno 7a     		  	
          		  </p>
          		  <p class="text-white">
          		  	09-213 Gozdowo    		  	
          		  </p>
          		  <p class="text-white">
          		  	{{ $t('nip') }} 774 306 15 11     		  	
          		  </p>
          		  <p class="text-white"> 
          		  	{{ $t('regon') }} 141517739
          		  </p>
          		  <p>
          		  	<a class="text-white" href="tel:669220424">Tel: <span>669 220 424</span></a>          		  	
          		  </p>
          		  <p>
          		  	<a class="text-white" href="tel:242615340">Tel/Fax: <span>24 26 15 340</span></a>          		  	
          		  </p>
          		  <p>
          		  	<a class="text-white" href="mailto:biuro@stalmat.eu">e-mail: <span>biuro@stalmat.eu</span></a>
          		  </p>
		        </div>
		        <div class="col-12 col-md-5 pt-4 offset-md-1 text-white" data-aos="fade-down" style="padding-bottom:20px">
		          <h2 class="text-left text-white" style="border-bottom: 1px solid black;">{{ $t('ContactUs') }}</h2>
		          	<form @submit.prevent="submit">
		          	  <div class="form-group" :class="{ 'form-group--error': $v.email.$error }">
					    <label for="exampleFormControlInput1">{{ $t('EmailAdress') }}</label>
					    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" v-model.trim="$v.email.$model">
					  </div>
					  <div class="form-group" :class="{ 'form-group--error': $v.title.$dirty && !$v.title.required }">
					    <label for="formGroupExampleInput">{{ $t('Title') }}</label>
					    <input type="text" class="form-control" id="formGroupExampleInput" v-model.trim="$v.title.$model">
					  </div>
					  <div class="form-group" :class="{ 'form-group--error': $v.text.$dirty && !$v.text.required  }">
					    <label for="exampleFormControlTextarea1">{{ $t('Message') }}</label>
					    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model.trim="$v.text.$model"></textarea>
					  </div>
					  <button type="button" class="btn btn-primary" v-on:click="postMail">{{ $t('Submit') }}</button>
					  <p class="typo__p" v-if="submitStatus === 'OK'">{{ $t('ThxForSubbmision') }}</p>
					  <p class="typo__p" v-if="submitStatus === 'ERROR'">{{ $t('ContactFormError') }}</p>
					  <p class="typo__p" v-if="submitStatus === 'PENDING'">{{ $t('Sending') }}</p>
					</form>
		        </div>
		      </div>
		    </div>
	  </div>
</template>
<script>
import Masthead from '@/components/Masthead.vue';
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { required,email } from 'vuelidate/lib/validators'
Vue.use(VueReCaptcha, { siteKey: '6LftFucUAAAAANHMTGb41vnwWzWh_ksZ77Trgprn' })
export default {
  	name: 'Contact',
  	data: function () {
   		return {
   			title:'',
   			text:'',
   			email:'',
   			submitStatus: null
   		}
   	},
   	metaInfo() {
	  	return{
	  		title: this._i18n.t('StalmatContact'),
		    meta: [
		      { 
		      	name: 'description', 
		      	content: 'STALMAT - Firma STALMAT powstała w 2005 roku. Głównym przedmiotem naszej działalności jest produkcja najwyższej jakości śrubowych elementów złącznych wykonanych według norm oraz dostarczonej dokumentacji i rysunków' 
		      },
		      { 
		      	name: 'keywords', 
		      	content: 'stalmat,śruby,śruby dwustronne,nakrętki,podkładki,sworznie' 
		      },
		      { 
		      	name: 'author', 
		      	content: 'https://www.itfrog.pl' 
		      },
		      { 
		      	name: 'robots', 
		      	content: 'index,follow' 
		      }
		    ]
	  	}
	  },
   	validations: {
	    email: {
	      required,
	      email
	    },
	    text:{
	    	required
	    },
	    title:{
	    	required
	    }
  	},
  	components: {
	    Masthead
	  },
	methods:{
		postMail() {
			if (this.$v.$invalid) {
				this.submitStatus = 'ERROR'
			}else{
				let formData = new FormData();
	            formData.append('title', this.title);
	            formData.append('text', this.text);
	            formData.append('email', this.email);
	            this.axios.post('./emailSender/sendMail.php',
	                formData,
	            ).then(function(data){
	              console.log(data.data);
	              location.reload();
	            })
	            .catch(function(){
	              console.log('FAILURE!!');
	            });
	        }
		}
	}
}
</script>